import {FaSoundcloud, FaSpotify, FaInstagram, FaYoutube, FaTiktok, FaTwitter, FaEnvelope} from 'react-icons/fa';

import sinsua_logo_spin from './sinsua_logo_spin.gif';
import sinsua_logo_spin_640_first from './sinsua_logo_spin_640_first.jpg';
import sinsua_logo_spin_640 from './sinsua_logo_spin_640.gif';
import ProgressiveImage from 'react-progressive-graceful-image'
function Header() {

  return (
    <div className="section text-section header">
      <header className="section">
        <ProgressiveImage src={sinsua_logo_spin_640} placeholder={sinsua_logo_spin_640_first}>
          {(src, loading) => (
            <img src={src} alt="an image" />
          )}
        </ProgressiveImage>
        <div className="center-links">

          <a href="https://open.spotify.com/artist/1P3H8YuH9gSjupdDcsTFd2?si=ScBpy1QWQfONoOo1mjbMFw"><FaSpotify /></a>
          <a href="https://twitter.com/sin_su_a"><FaTwitter /></a>
          <a href="https://www.instagram.com/sin.su.a/"><FaInstagram /></a>
          <a href="https://www.tiktok.com/@sin.su.a"><FaTiktok /></a>
          <a href="https://www.youtube.com/@sin_su_a"><FaYoutube /></a>
        </div>
      </header>
    </div>
  )
}

export default Header;

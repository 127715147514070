
import {SearchBox} from '@mapbox/search-js-react';
import axios from 'axios';


import {useState, useRef} from 'react';
import background from './sinsua_background_about.png';
import {FaSoundcloud, FaSpotify, FaInstagram, FaYoutube, FaTiktok, FaTwitter, FaEnvelope} from 'react-icons/fa';

// <div className=highlight></div>
// <a href="https://soundcloud.com/sinsua">Spotify</a></div>



function CallToAction(props: {height: number}) {
  let form = null;
  let [formComplete, setFormComplete] = useState(false);
  let [captchaToken, setCaptchaToken] = useState(null);
  let captchaRef = useRef<any>(null);
  let [email, setEmail] = useState('');

  interface Window {
    grecaptcha: any;
    test: () => void;
  }

  let handleSubmit = async (e: any) => {
    e.preventDefault();
    (window as any).grecaptcha.ready(function () {
      (window as any).grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_APP_SITE_KEY, {action: 'submit'}).then(async function (token: any) {
        // Add your logic to submit to your backend server here.
        await axios.post(process.env.REACT_APP_API_URL + '/addemail', {token, email})
          .then((res) => {
            setFormComplete(true);
            console.log(res);
          })
          .catch((error) => {
            console.log(error);
          }

          );
      });
    });

  }

  const verify = () => {
    captchaRef.current.getResponse().then((res: any) => {
      setCaptchaToken(res)
    })
  }

  if (formComplete) {
    form = (<p>Thank You!</p>);
  } else {
    form = (<form onSubmit={handleSubmit}>
      <input type='email' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Enter your email' />

      <input type='submit' />

    </form>
    )
  }
  // <SearchBox accessToken='pk.eyJ1Ijoic2luc3VhIiwiYSI6ImNsZDVieWp1cTBtOWYzcnBxOGYxNDM4dzIifQ.J5Geah_ffYnYnZLl_ogamA' options={{types: "place"}}/>

  return (
    <div className="section text-section cta" style={{
      backgroundImage: `url('${background}')`,
      height: props.height

    }}>
      <div className="cta-text">
        <p>
          sin/su/a is a queer artist hailing from the SF Bay Area. She's currently exploring her inner demons in Berlin.
        </p>
        <p>
          Her first single, <a href="https://songwhip.com/sinsua/hurt">Hurt</a>, is <a href="https://songwhip.com/sinsua/hurt">out now on all platforms</a>.

        </p>
        <div className="email-form">
          {form}
          <div className="g-recaptcha"
            data-sitekey="6LcRFRUkAAAAAB1S_g7tVY8CEprZ8ODo1jpEt6iS"
            data-callback="onSubmit"
            data-size="invisible">
          </div>

        </div>
      </div>


    </div>
  );
}

export default CallToAction;

import ProgressiveImage from 'react-progressive-graceful-image'
import {useRef, useEffect } from 'react';

function ImageSection (props: {image:string, placeholder: string, height?:Function}) {
  let ref = useRef<any>(null);

  let heightCheck = () => {
    if(props.height && ref.current != null) {

      props.height(ref.current.clientHeight);
    }
  }
  useEffect(heightCheck);

  window.addEventListener('resize', heightCheck)
  window.addEventListener('load', heightCheck)
  window.addEventListener('DOMContentLoaded', heightCheck)



  return (
    <div ref={ref} className="section image-section">
      <ProgressiveImage src={props.image} placeholder={props.placeholder}>
        {(src, loading) => (
          <img className="big-image" src={props.image}/ >
        )}
      </ProgressiveImage>
    </div>
  );
}

export default ImageSection;

import React from 'react';
import logo from './logo.svg';
import headshot1 from './Sinsua_Portrait-26.jpg';
import headshot1_placeholder from './Sinsua_Portrait-26-lowres.jpg';
import headshot2 from './Sinsua_Portrait-37.jpg';
import headshot2_placeholder from './Sinsua_Portrait-37-lowres.jpg';
import './normalize.css';
import './sakura.css';
import './home.css';
import ImageSection from './ImageSection'
import About from './About'
import CallToAction from './CallToAction'
import Header from './Header'
import Footer from './Footer'
import {useState} from 'react';

          // <img className="logospin" src={sinsua_logo_spin} />
function App() {
  let [height, setHeight] = useState(0);
  return (
    <div className="wrapper">
    <div className="metacontainer">
      <Header />
      <ImageSection image={headshot2} placeholder={headshot2_placeholder} height={setHeight} />
      <CallToAction height={height} />
      <ImageSection image={headshot1} placeholder={headshot1_placeholder}/>
      </div>
      <Footer/ >
    </div>
  );
}
      // <About height={height} />


  // return (
  //   <div className="App">
  //     <header className="App-header">
  //       <img src={logo} className="App-logo" alt="logo" />
  //       <p>
  //         Edit <code>src/App.tsx</code> and save to reload.
  //       </p>
  //       <a
  //         className="App-link"
  //         href="https://reactjs.org"
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         Learn React
  //       </a>
  //     </header>
  //   </div>
  // );
// }

export default App;

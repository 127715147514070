

function Footer () {
  return (
    <div className="footer">
      <div className="recaptcha">
        This site is protected by reCAPTCHA and the Google  <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </div>
    </div>
  );
}

export default Footer;
